<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {
  }
}
</script>
<style lang="less">
@import "./App.less";
</style>
