import Vue from "vue"
import App from "./App.vue"
import router from "./router"

import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'

import {
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Button,
  Message
} from 'element-ui'
Vue.prototype.$message = Message;
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.prototype.$wow = wow

Vue.config.productionTip = false
Vue.prototype.toPath = function (name) {
  this.$router.push({ name: name })
}
new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
