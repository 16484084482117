import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/pc",
    name: "index",
    meta: {
      title: '武汉融智'
    },
    component: () => import(/* webpackChunkName: "home" */ "@v/pc/index.vue"),
    children:[
      {
        path: "home",
        name: "home",
        meta: {
          title: '武汉融智-官网首页'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/pc/home/index.vue")
      },
      {
        path: "product",
        name: "product",
        meta: {
          title: '武汉融智-产品中心'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/pc/product/index.vue")
      },
      {
        path: "solution",
        name: "solution",
        meta: {
          title: '武汉融智-解决方案'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/pc/solution/index.vue")
      },
      {
        path: "example",
        name: "example",
        meta: {
          title: '武汉融智-客户案例'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/pc/example/index.vue")
      },
      {
        path: '/pc',
        redirect: 'home'
      }
    ]
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: '武汉融智-关于我们'
    },
    component: () => import(/* webpackChunkName: "home" */ "@v/about/index.vue"),
    children: [
      {
        path: "introduce",
        name: "introduce",
        meta: {
          title: '武汉融智-公司简介'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/about/introduce/index.vue")
      },
      {
        path: "culture",
        name: "culture",
        meta: {
          title: '武汉融智-企业文化'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/about/culture/index.vue")
      },
      {
        path: "process",
        name: "process",
        meta: {
          title: '武汉融智-发展历程'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/about/process/index.vue")
      },
      {
        path: "honor",
        name: "honor",
        meta: {
          title: '武汉融智-荣誉与资质'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/about/honor/index.vue")
      },
      {
        path: "contact",
        name: "contact",
        meta: {
          title: '武汉融智-联系我们'
        },
        component: () => import(/* webpackChunkName: "home" */ "@v/about/contact/index.vue")
      },
      {
        path: "/about",
        redirect: 'introduce'
      }
    ]
  },
  {
    path: "/trial",
    name: "trial",
    meta: {
      title: '武汉融智-申请试用'
    },
    component: () => import(/* webpackChunkName: "home" */ "@v/trial/index.vue")
  },
  {
    path: "*",
    redirect: '/pc'
  }
]

const router = new VueRouter({
  routes
})
router.afterEach(function (to) {
  window.scrollTo(0, 0)
  document.title = to.meta.title || ''
})

export default router;
